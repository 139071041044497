<template>
	<div class="body " style="">
	 
		<!-- 轮播 -->
		<div class="swiper_box">
			<div class="frontTeachers-swiper">
				<div class="swiper-wrapper">
					<div class="swiper-slide" v-for="teachers in teacherList">
						<!-- {{teachers.img}} -->
						<img :src="teachers.img" alt="照片"  />
						
						<!-- <div class="bj" :style="{background: 'url('+teachers.img+')', backgroundSize: '100% 100% ',backgroundRepeat:'no-repeat'}"></div> -->
						 
					</div>
				</div>
			</div>
			<!-- 左右切换 -->
			<!-- <div class="left_change">
				<img src="../../assets/images/train_change.png" alt="上一个" />
			</div>
			<div class="right_change">
				<img src="../../assets/images/train_change.png" alt="下一个" />
			</div> -->
		</div> 
	</div>
</template>

<script>
	import Swiper from "swiper";
	import "swiper/css/swiper.min.css";
	import "../../assets/css/newPublic.css";
	export default{
		props:['imgdata'],
		data(){
			return{
				teacherList:[ 
				],
			};
		},
		created() {
			console.log('aaa')
		},
		watch:{
			immediare:true,
			imgdata(val){
				this.teacherList = []
				// this.teacherList=JSON.parse(JSON.stringify(val))
						 // setTimeout(()=>{
							//  this.teacherList = val
							//  console.log(this.teacherList)
						 // },2000)
			}
		},
		created() {
			this.teacherList = this.imgdata;
			this.$nextTick(()=>{
				let swiper_box = new Swiper(".frontTeachers-swiper", {
					slidesPerView: 3,
					centeredSlides:true,
					// slidesPerview:'auto',
					slidesPerGroup:1, 
					crossFade:true,
					coverflowEffect:true,
					spaceBetween:50,//间隙
					observer: true, //修改swiper自己或子元素时，自动初始化swiper
					observeParents: true, //修改swiper的父元素时，自动初始化swiper
					loop: true,
					autoplay: {
						autoplayDisableOnInteraction: false,
						disableOnInteraction: false,
						delay: 800,
					},
					
					});
			})
		
		},
		mounted(){
			
				// 如果需要前进后退按钮
				// navigation: {
				//   nextEl: ".left_change",
				//   prevEl: ".right_change",
				// },
				
				 
				   
				
			
		},
		methods:{
			
		}
	}
</script>

<style lang="scss" scoped>
	.body{
		height: 35rem;
		width: 50rpx !important;
		position: relative; 
	}
	.swiper_box{
		width: 94rem;
		overflow: hidden;
	}
	 img{
		 width: 80%;
		 
	 }
	.swiper-wrapper{
		width: 35rem;
		// height: 600px;
		// background: #ccc;
		// box-sizing: border-box;
	}
	.swiper-slide{
		// border:1px solid #000000
	}
	 .swiper-slide-prev{
		 // border: 10px solid bisque;
		 // width: 200px !important;
	 }
	.swiper-slide-active{
		// border: 10px solid red;
		// width: 50rem !important;
		// margin-left: 200px;
		margin: 0 auto;
	}
	.swiper-slide-next{
		// border: 10px solid darkblue;
		// transform: scale(0.8);
		// width: 200px !important;
	}
	.bj{
		width: 100%;
		height: 100%;
	}
</style>

import { render, staticRenderFns } from "./huanjing.vue?vue&type=template&id=954cee0e&scoped=true&"
import script from "./huanjing.vue?vue&type=script&lang=js&"
export * from "./huanjing.vue?vue&type=script&lang=js&"
import style0 from "./huanjing.vue?vue&type=style&index=0&id=954cee0e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "954cee0e",
  null
  
)

export default component.exports
<template>
	<div class="body mybody" style="overflow: hidden;">
	  
		<div class="swiper-container">
		  <!-- Additional required wrapper -->
		  <div class="swiper-wrapper">
		   
		    <div class="swiper-slide"><img src="../../assets/images/guanwang1.png" alt=""></div>
		    <div class="swiper-slide"><img src="../../assets/images/guanwang3.png" alt=""></div>
		    <div class="swiper-slide"><img src="../../assets/images/guanwang4.png" alt=""></div>
		     
		  </div>
		  
		</div>
		
		
		
		
	</div>
</template>

<script>
	import Swiper from "swiper";
	import "swiper/css/swiper.min.css";
	import "../../assets/css/newPublic.css";
	export default{
		props:['imgdata'],
		data(){
			return{
				teacherList:[ 
				],
			};
		},
		created() {
			console.log('aaa')
		},
		watch:{
		 
		},
		created() {
		 
		
		},
		mounted(){
			
			const swiper = new Swiper('.mybody .swiper-container', {
			  // Optional parameters
			  // direction: 'vertical',
			  loop: true,
			  spaceBetween:10,
			  slidesPerView:1.4,
			  centeredSlides:true,
			autoplay: {
				autoplayDisableOnInteraction: false,
				disableOnInteraction: false,
				delay: 1000,
			},
			  // If we need pagination
			  pagination: {
			    el: '.swiper-pagination',
			  },
			
			  // Navigation arrows
			  navigation: {
			    nextEl: '.swiper-button-next',
			    prevEl: '.swiper-button-prev',
			  },
			
			  // And if we need scrollbar
			  scrollbar: {
			    el: '.swiper-scrollbar',
			  },
			  });
				   
				
			
		},
		methods:{
			
		}
	}
</script>

<style lang="scss" scoped>
	
	
	@import '../../assets/css/lunbo_big.css';
</style>
